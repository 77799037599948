<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="审批"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>

    <div class="content">
      <div class="examinationtitle">审批意见</div>
      <van-field
        v-model="message"
        rows="5"
        autosize
        type="textarea"
        placeholder="请输入留言"
        show-word-limit
      />
    </div>
    <div class="footer">
      <van-button round type="info" @click="submit" class="footerbut"
        >确定</van-button
      >
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
// import { Toast } from 'vant';
export default {
  data() {
    return {
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3"],
      message: "不同意",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    submit() {
      if (this.message === "不同意") {
        Dialog.alert({
          message: "请填写具体不批准原因",
        }).then(() => {

        });
      } else {
        this.$emit('onSubmit', 2, this.message)
      }
    },
  },
};
</script>
<style lang="less" scoped>
</style>
